


























































































































































































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import moment from 'moment'
import debounce from 'lodash/debounce'
import StringConversions from '@/mixins/StringConversions'
import { Api } from '@/edshed-common/api'
import { EventLogInfo, EventType, UserModel, GetEventsParams, SchoolModel, TableQuery, TableState } from '@/edshed-common/api/types'

interface EventDetails {
  id: number
  user: UserModel
  school: SchoolModel
}

@Component({
  components: {
  }
})
export default class EventsView extends mixins(StringConversions) {
  logData: EventLogInfo[] = []
  response: string = ''
  loading: boolean = false
  logTableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'created',
    dir: 'desc',
    term: ''
  }

  logDetails: EventDetails[] = []
  logTotal: number = 0
  filteredEventTypes: EventType[] = []
  filteredUser: UserModel | null = null
  filteredDates: Date[] = []
  filteredIP: string = ''
  filteredSchool: SchoolModel | null = null
  isOpen: boolean = false
  userSearchResults: Array<any> = []
  schoolSearchResults: Array<any> = []
  isFetching: boolean = false

  get isAdmin () {
    return (this.$store.state.user.school.admin === 1)
  }

  get eventTypes () {
    return EventType
  }

  mounted () {
    this.$nextTick(() => {
      // this.getLogData()
    })
  }

  getLogData () {
    const params: any = {}

    if (this.filteredEventTypes) {
      params.types = this.filteredEventTypes
    }

    if (this.filteredUser) {
      params.user_id = this.filteredUser.id
    }

    if (this.filteredDates && this.filteredDates.length) {
      params.created_after = this.filteredDates[0].toISOString()
      params.created_before = this.filteredDates[1].toISOString()
    }

    if (this.filteredIP) {
      params.ip_address = this.filteredIP
    }

    if (this.filteredSchool) {
      params.school_id = this.filteredSchool.id
    }

    const { perPage, page, sort, dir, term } = this.logTableState

    const tableQuery: TableQuery = {
      take: perPage,
      skip: perPage * (page - 1),
      sort,
      dir,
      term
    }

    Api.getEvents(params, tableQuery).then((response) => {
      this.logData = response.items
      this.logTotal = response.total
    })
      .catch((err) => {
        console.log(err)
      })
  }

  toggleLoading () {
    this.loading = !this.loading
  }

  dateFromServerDate (dt) {
    if (dt === null || dt === '' || !moment(dt).isValid()) {
      return null
    }
    const m = moment(dt).local()
    const dts = m.toDate().toLocaleDateString() + ' ' + m.format('HH:mm')
    return dts
  }

  sortChanged (field, dir) {
    this.logTableState.sort = field
    this.logTableState.dir = dir

    this.getLogData()
  }

  pageChanged (page) {
    this.logTableState.page = page
    this.getLogData()
  }

  userSelected (option) {
    this.filteredUser = option
  }

  schoolSelected (option) {
    this.filteredSchool = option
  }

  formatEventTypeName (eventType: string) {
    return eventType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  searchUsersDebounced = debounce((name) => {
    this.searchUsers(name)
  }, 500)

  searchUsers (name) {
    if (!name.length) {
      this.userSearchResults = []
    }

    this.isFetching = true

    Api.searchUsers(name)
      .then((response) => {
        this.userSearchResults = response
      })
      .catch((err) => {
        console.log(err)
        this.userSearchResults = []
        throw err
      })
      .finally(() => {
        this.isFetching = false
      })
  }

  searchSchoolsDebounced = debounce((school) => {
    this.searchSchools(school)
  }, 500)

  searchSchools (school: string) {
    if (!school.length) {
      this.schoolSearchResults = []
    }

    this.isFetching = true

    Api.searchSchools(school, false)
      .then((response) => {
        this.schoolSearchResults = response
      })
      .catch((err) => {
        console.log(err)
        this.schoolSearchResults = []
        throw err
      })
      .finally(() => {
        this.isFetching = false
      })
  }
}
